<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card class="mx-auto">
                    <v-card-title>{{ pageTitle }}</v-card-title>

                    <v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="opinion.customer_name"
                                        label="Customer name"
                                        required
                                        :error-messages="customerNameErrors"
                                        @input="$v.opinion.customer_name.$touch()"
                                        @blur="$v.opinion.customer_name.$touch()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="opinion.company"
                                        label="Company name"
                                        required
                                        :error-messages="companyErrors"
                                        @input="$v.opinion.company.$touch()"
                                        @blur="$v.opinion.company.$touch()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <wysiwyg-editor
                                        v-model="opinion.content"
                                        label="Opinion content"
                                        :key="opinion.id"
                                        :error-border="contentErrors.length !== 0"
                                    />
                                </v-col>
                                <v-col cols="12" align="end" justify="center">
                                    <v-btn color="success" @click="save">Save</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import OpinionValidation from '@/components/opinions/opinion-validation.mixin';
import WysiwygEditor from '@/components/elements/WysiwygEditor';

export default {
    components: {
        WysiwygEditor
    },

    mixins: [OpinionValidation],

    data: () => ({
        pageTitle: 'New option',
        opinion: {},
        editMode: false
    }),

    async mounted() {
        const id = this.$route.params.id;

        if (id) {
            try {
                this.opinion = await this.getOpinion(id);
                this.pageTitle = 'Edit option';
                this.editMode = true;
            } catch (err) {
                console.error(err);

                this.$toasted.error('Something went wrong!');
            }
        }
    },

    methods: {
        ...mapActions({
            addOpinion: 'opinions/addOpinion',
            getOpinion: 'opinions/getOpinion',
            updateOpinion: 'opinions/updateOpinion'
        }),

        async save() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            try {
                if (!this.editMode) {
                    await this.addOpinion({
                        opinionData: this.opinion
                    });

                    this.$toasted.success('Opinion has been added!');
                } else {
                    await this.updateOpinion({
                        id: this.$route.params.id,
                        opinionData: this.opinion
                    });

                    this.$toasted.success('Opinion has been updated!');
                }

                this.opinion = {};
                this.$v.$reset();
                this.$router.push('/opinions');
            } catch (err) {
                console.error(err);

                this.$toasted.error('Something went wrong!');
            }
        }
    }
};
</script>

<style>
</style>