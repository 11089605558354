<template>
    <div v-if="editorId" :class="{ 'error-border': errorBorder }">
        <label v-if="label" :for="editorId">{{ label }}</label>
        <div v-if="editorId" :id="editorId" v-bind="$attrs">
            <p>Hello World!</p>
        </div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,

    props: {
        value: {
            required: false,
            default: '',
            type: String
        },
        label: {
            required: false,
            default: '',
            type: String
        },
        errorBorder: {
            required: false,
            default: false,
            type: Boolean
        }
    },

    data() {
        return {
            editorId: null
        };
    },

    created() {
        this.editorId = Math.random()
            .toString(36)
            .substring(2, 15)
            .match(/[a-zA-Z]+/g)
            .join('');
    },

    async mounted() {
        await this.$nextTick();

        let modules = {
            toolbar: [
                [{ font: [] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ align: [] }],
                ['clean'],
                ['link']
            ]
        };

        /* eslint-disable */
        const quillEditor = new Quill(`#${this.editorId}`, {
            theme: 'snow',
            modules,
            bounds: window.document.body
        });

        let Block = Quill.import('blots/block');
        Block.tagName = 'DIV';
        Quill.register(Block, true);

        quillEditor.root.innerHTML = this.value;

        quillEditor.on('text-change', () => {
            this.$emit('input', quillEditor.root.innerHTML);
        });
    }
};
</script>

<style>
.ql-editor {
    min-height: 150px;
}

.error-border .ql-editor {
    border: 1px solid #dc3545 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.ql-tooltip.ql-hidden {
    display: none;
}
</style>
