import { required } from 'vuelidate/lib/validators';

export default {
    validations: {
        opinion: {
            customer_name: { required },
            company: { required },
            content: {
                requiredWysiwyg: val => {
                    const regex = /(<([^>]+)>)/gi;

                    return val.replace(regex, '') !== '';
                }
            }
        }
    },
    computed: {
        customerNameErrors() {
            const errors = [];
            if (!this.$v.opinion.customer_name.$dirty) return errors;
            !this.$v.opinion.customer_name.required &&
                errors.push('Customer name is required.');

            return errors;
        },

        companyErrors() {
            const errors = [];
            if (!this.$v.opinion.company.$dirty) return errors;
            !this.$v.opinion.company.required &&
                errors.push('Company name is required.');

            return errors;
        },

        contentErrors() {
            const errors = [];
            if (!this.$v.opinion.content.$dirty) return errors;
            !this.$v.opinion.content.requiredWysiwyg &&
                errors.push('Opinion content is required.');

            return errors;
        }
    }
};
